const trackingFocusEvent = ($content) => {
    const $$trackingElements = $content.querySelectorAll('[data-tracking-focus]');

    const addEvent = ($trackingElement) => {
        $trackingElement.addEventListener('focus', () => {
            const formName = $trackingElement.dataset.trackingFocus;

            if (formName) {
                trackingSend({
                    event: 'form_view',
                    formName,
                    context: window.location.href
                });
            }
        });
    };

    $$trackingElements.forEach(($trackingElement) => {
        addEvent($trackingElement);
    });
};

const trackingSend = (data) => {
    if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push(data);
    }
};

const checkFormInputs = ($form) => {
    const $$inputs = $form.querySelectorAll('input, select');
    let allFilled = true;
    let noneFilled = true;
    const radioGroups = {};

    $$inputs.forEach((input) => {
        if (input.type === 'text' || input.tagName.toLowerCase() === 'select') {
            if (input.value.trim() === '') {
                allFilled = false;
            } else {
                noneFilled = false;
            }
        } else if (input.type === 'checkbox') {
            if (input.checked) {
                noneFilled = false;
            } else {
                allFilled = false;
            }
        } else if (input.type === 'radio') {
            if (!radioGroups[input.name]) {
                radioGroups[input.name] = { filled: false, anyChecked: false };
            }
            if (input.checked) {
                radioGroups[input.name].anyChecked = true;
                noneFilled = false;
            }
        }
    });

    for (const group in radioGroups) {
        if (!radioGroups[group].anyChecked) {
            allFilled = false;
        }
    }

    if (noneFilled) {
        return 'empty';
    } else if (allFilled) {
        return 'full';
    } else {
        return 'partial';
    }
};

export {
    trackingFocusEvent,
    trackingSend,
    checkFormInputs
};
